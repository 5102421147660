import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import PlayIcon from "../icons/play"

export default function ChannelCard({ channel, type }) {
    const image = getImage(channel.data.image.localFile)
    const alt =
        type === "replay"
            ? `Schauen Sie alle Sendungen von ${channel.data.name.text}  On-Demand direkt von Ihrem Computer oder Smartphone. Gratis und unbegrenzt`
            : `Sehen Sie Das ${channel.data.name.text} live aus dem Internet direkt auf Ihr Gerät: gratis und unbegrenzt`

    return (
        <div>
            <Link
                className="group relative flex"
                to={`${
                    type === "live"
                        ? `/live/${channel.uid}`
                        : `/replay/${channel.uid}`
                }`}
                title={channel.data.name.text}
            >
                <GatsbyImage image={image} alt={alt} />
                {type === "replay" && (
                    <div
                        className="z-10 absolute top-2 right-2 px-3 py-2 text-xs text-white"
                        style={{ background: "rgba(0,0,0,.3)" }}
                    >
                        Replay
                    </div>
                )}
                <div className="absolute inset-0 flex flex-col items-center justify-center w-full bg-black opacity-0 group-hover:opacity-90 transition">
                    <PlayIcon className="mb-2 text-blue-700" width="40" />
                    <span className="text-white">{channel.data.name.text}</span>
                </div>
            </Link>
        </div>
    )
}
