import * as React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PrismicLink from "../components/prismicLink"

import PlayIcon from "../components/icons/play"
import Similar from "../components/shared/similar"
import AdSlot from "../components/shared/adSlot"

const ReplayChannel = ({ data, location }) => {
    if (!data) return null
    const document = data.prismicChannel.data
    const settings = data.prismicGeneralSettings.data

    const handleClick = () => {
        window.open(
            document.replay_channel_link?.url,
            "newWin",
            "toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=1, resizable=0, width=1200, height=665"
        )
    }

    const currentUrl = location.href ? location.href : ""

    return (
        <Layout>
            <SEO
                title={
                    document.replay_channel_seo_title?.text ||
                    document.replay_channel_title?.text
                }
                description={document.replay_channel_seo_description?.text}
                // ogImage={}
            />
            <div className="grid md:grid-cols-3 gap-16 mx-auto px-6 pt-12 pb-10 max-w-7xl">
                <div className="md:col-span-2">
                    <div className="pb-4 border-b border-gray-300">
                        <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                            {RichText.asText(
                                document.replay_channel_title?.raw
                            )}
                        </h1>
                    </div>

                    {/* Replay Channel Top Article Slot */}
                    <AdSlot
                        html={settings.replay_channel_top_article_slot?.text}
                    />

                    <div className="mt-4 pb-10 prose prose-lg max-w-none text-gray-600">
                        <RichText
                            render={document.replay_channel_content?.raw}
                            serializeHyperlink={PrismicLink}
                        />

                        {/* Replay Channel Before Button Slot */}
                        <AdSlot
                            html={settings.replay_channel_in_article_slot?.text}
                        />

                        <p>
                            Das Netzwerk Das Erste {document.name?.text} bietet
                            hunderte von Shows und Serien, die man sich erneut
                            ansehen kann und das Ganze ist gratis und legal.
                            Egal, ob Reportagen, Sport-Events oder eine Show,
                            Wiederholungen sind häufig gratis und verfügbar für
                            Sie.
                        </p>

                        {/* Replay Channel Before Button Slot */}
                        <AdSlot
                            html={
                                settings.replay_channel_before_button_slot?.text
                            }
                        />

                        <button
                            className="flex items-center justify-center w-full p-4 text-base sm:text-lg text-white rounded-md bg-blue-700 hover:bg-blue-900 appearance-none focus:outline-none transition"
                            onClick={handleClick}
                        >
                            <PlayIcon className="flex-shrink-0 mr-3 sm:w-8" />
                            <span>
                                Zugang zu dem ganzen Programm von{" "}
                                {document.name?.text} On-Demand
                            </span>
                        </button>

                        {/* Replay Channel After Button Slot */}
                        <AdSlot
                            html={
                                settings.replay_channel_after_button_slot?.text
                            }
                        />

                        <RichText
                            render={settings.replay_channel_foot_text?.raw}
                            serializeHyperlink={PrismicLink}
                        />
                    </div>

                    <div
                        className="fb-comments"
                        data-href={currentUrl}
                        data-width="100%"
                        data-numposts="5"
                    />
                </div>
                <div className="pb-10">
                    {/* Replay Channel Top Right Slot */}
                    <AdSlot
                        html={settings.replay_channel_top_right_slot?.text}
                    />

                    <a
                        className="twitter-timeline"
                        data-height="350"
                        href={`https://twitter.com/${document.twitter_handle?.text}`}
                    >
                        Tweets by ${document.twitter_handle?.text}
                    </a>
                </div>
            </div>

            {/* Replay Channel Suggestions Top Slot */}
            <div className="flex justify-center">
                <AdSlot
                    html={settings.replay_channel_suggestions_top_slot?.text}
                />
            </div>

            <Similar channels={data.channels} type="replay" />
        </Layout>
    )
}

export default withPreview(ReplayChannel)

export const query = graphql`
    query ReplayChannelQuery($uid: String!) {
        prismicChannel(uid: { eq: $uid }) {
            data {
                replay_channel_title {
                    text
                    raw
                }
                replay_channel_seo_title {
                    text
                }
                replay_channel_seo_description {
                    text
                }
                replay_channel_content {
                    raw
                }
                replay_channel_link {
                    url
                }
                name {
                    text
                }
                twitter_handle {
                    text
                }
            }
        }
        prismicGeneralSettings {
            data {
                replay_channel_top_article_slot {
                    text
                }
                replay_channel_top_right_slot {
                    text
                }
                replay_channel_in_article_slot {
                    text
                }
                replay_channel_before_button_slot {
                    text
                }
                replay_channel_after_button_slot {
                    text
                }
                replay_channel_suggestions_top_slot {
                    text
                }
                replay_channel_foot_text {
                    raw
                }
            }
        }
        channels: allPrismicChannel {
            nodes {
                uid
                data {
                    name {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
